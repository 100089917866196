import Swiper, { Pagination, Autoplay } from 'swiper/swiper.esm.js'

Swiper.use([Pagination, Autoplay])

class SwiperManager {
  swiper = null

  constructor() {
    // Rely on turbolinks event to initialize on each page load
    document.addEventListener('turbolinks:load', this.onPageLoad)
  }

  onPageLoad() {
    let element = document.querySelector('div.swiper')

    if(element === undefined)
      return

    window.swiper_manager.startSwiper()
  }

  startSwiper() {
    window.swiper_manager.swiper = new Swiper(
      ".swiper",
      {
        pagination: {
          el: ".swiper-pagination",
        },
        loop: true,
        slidesPerView: 1.25,
        centeredSlides: true,
        spaceBetween: 10,
        autoplay: true,
      }
    )
  }
}

window.swiper_manager = new SwiperManager()
