class AuthManager {
  constructor() {
    this.page = 1

    // Rely on turbolinks event to initialize on each page load
    document.addEventListener('turbolinks:load', this.onPageLoad)
  }

  onPageLoad() {
    let obj = window.auth_manager
    let for_level = document.getElementById('for')?.value

    // Act only for users
    if(for_level != 'user')
      return

    // Hide/Display elements
    obj.changePage()

    // Hook on type on email field
    document.getElementById('user_email').addEventListener('keyup', obj.onTypeEmail)
    // Hook on select a pseudo suggestion
    document.addEventListener('click', obj.onClickPseudoSuggestion)
    // Hook on "Continue" button & form submit (user press enter)
    document.getElementById('new_user').addEventListener('submit', obj.onFormSubmit)
    document.querySelector('.actions a.submit_btn').addEventListener('click', obj.onContinue)
  }

  onTypeEmail() {
    let element = document.querySelector('.email_taken')
    if(element)
      element.classList.add('hidden')
  }

  onClickPseudoSuggestion(event) {
    if(event.target.classList.contains('pseudo_suggest')) {
      event.preventDefault()

      document.getElementById('user_pseudo').value = event.target.innerText
    }
  }

  changePage() {
    let obj = window.auth_manager

    document.querySelectorAll('.user_page_1').forEach(element => {
      element.classList.toggle('hidden', obj.page == 2)
    })
    document.querySelectorAll('.user_page_2').forEach(element => {
      element.classList.toggle('hidden', obj.page == 1)
    })
  }

  onFormSubmit(event) {
    let obj = window.auth_manager

    if(obj.page == 1) {
      event.stopPropagation()
      obj.onContinue(event)
    }
  }

  onContinue(event) {
    let obj = window.auth_manager

    event.preventDefault()

    // Display page 2
    obj.page = 2
    obj.changePage()
  }
}

window.auth_manager = new AuthManager()
